<template>
  <v-row class="edmonton-symptoms mt-1">
    <v-col class="pt-0 pb-0" cols="12">
      <div class="h2 mb-5 pl-1 title">{{ $t("psychometric_reports") }}</div>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-container class="padding-container pt-0">
        <v-row>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="PHQ-4"
              date="03/08/2021"
              :data="phqData"
            />
          </v-col>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="MARS-5"
              date="04/08/2021"
              :data="marsData"
            />
          </v-col>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="VES-13"
              date="03/05/2021"
              :data="vesData"
            />
          </v-col>
          <v-col cols="6">
            <SingleProgressBarSetCard
              title="LASA"
              date="03/06/2021"
              :data="lasaData"
            />
          </v-col>
          <v-col cols="12">
            <!-- <ESASCard title="ESAS" date="03/05/2021" :data="esasData" /> -->
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SingleProgressBarSetCard from "@/components/views/dashboard/members/id/partial/cards/SingleProgressbarSetCard.vue";
// import ESASCard from "@/components/views/dashboard/members/id/partial/cards/ESASCard.vue";

export default {
  name: "psychometric-reports",

  data() {
    return {
      phqData: {
        score: 8,
        totalScore: 12,
        redirectionLink: `/${this.$i18n.locale}/members/${this.$route.params.id}/phq`,
      },
      marsData: {
        score: 24,
        totalScore: 50,
        redirectionLink: `/${this.$i18n.locale}/members/${this.$route.params.id}/mars`,
      },
      vesData: {
        score: 2,
        totalScore: 10,
        redirectionLink: `/${this.$i18n.locale}/members/${this.$route.params.id}/ves`,
      },
      lasaData: {
        score: 64,
        totalScore: 100,
        redirectionLink: `/${this.$i18n.locale}/members/${this.$route.params.id}/lasa`,
      },
      esasData: {
        worst: 4,
        same: 5,
        reduce: 2,
        fatigue: 6,
        redirectionLink: `/${this.$i18n.locale}/members/${this.$route.params.id}/esas`,
        joints: [
          {
            option: "first",
          },
          {
            option: "fifth",
          },
          {
            option: "sixth",
          },
          {
            option: "bottom-first",
          },
          {
            option: "bottom-second",
          },
          {
            option: "bottom-sixth",
          },
           {
            option: "body-center",
          },
        ],
      },
    };
  },

  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
    reportedDate: {
      type: String,
      default: "",
    },
  },

  components: {
    SingleProgressBarSetCard,
    // ESASCard,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/psychometric-reports.scss">
</style>
